import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Hjem',
		component: Home,
		meta: {
			title: 'Ellingsen Byggservice AS',
			subtitle: 'Vi bygger din drøm!',
		},
		props: {
			topImage: require('@/assets/top-images/home3.jpg'),
			topImagePhone: require('@/assets/top-images/home_phone.jpg')
		},
	},
	{
		path: '/kontakt',
		name: 'Kontakt',
		component: () => import('../views/Kontakt.vue'),
		meta: {
			title: 'Kontakt',
		},
	},
	{
		path: '/om-oss/',
		name: 'Om oss',
		component: () => import('../views/About.vue'),
		meta: {
			title: 'Om oss',
			// topImage: require('@/assets/top-images/about.jpg'),
		},
	},
	{
		path: '/nybygg',
		name: 'Nybygg/Tilbygg',
		component: () => import('../views/Tilbygg.vue'),
		meta: {
			title: 'Nybygg og Tilbygg',
		},
		props: {
			topImage: require('@/assets/top-images/nybygg.jpeg'),
		},
	},
	{
		path: '/kjokken',
		name: 'Kjøkken',
		component: () => import('../views/Kjokken.vue'),
		meta: {
			title: 'Kjokken',
		},
		props: {
			topImage: require('@/assets/top-images/Kjokken.jpg'),
		},
	},
	{
		path: '/bad',
		name: 'Bad',
		component: () => import('../views/Bad.vue'),
		meta: {
			title: 'Bad',
		},
		props: {
			topImage: require('@/assets/top-images/bad.jpg'),
		},
	},
	{
		path: '/vaskerom',
		name: 'Vakserom',
		component: () => import('../views/Vaskerom.vue'),
		meta: {
			title: 'Vaskerom',
		},
		props: {
			topImage: require('@/assets/top-images/Vaskerom.jpg'),
		},
	},
	{
		path: '/totalrenovering',
		name: 'Totalrenovering',
		component: () => import('../views/Totalrenovering.vue'),
		meta: {
			title: 'Totalrenovering',
		},
		props: {
			topImage: require('@/assets/top-images/totalrenovering.jpg'),
		},
	},
	{
		path: '/spesialrom',
		name: 'Spesialsrom',
		component: () => import('../views/Spesialrom.vue'),
		meta: {
			title: 'Spesialrom',
		},
		props: {
			topImage: require('@/assets/top-images/spesialrom_merged.jpg'),
		},
	},
	{
		path: '/restaurering',
		name: 'Restaurering',
		component: () => import('../views/Restaurering.vue'),
		meta: {
			title: 'Restaurering',
		},
		props: {
			topImage: require('@/assets/top-images/restaurering.jpg'),
		},
	},
	{
		path: '/treverksmobler',
		name: 'Treverksmobler',
		component: () => import('../views/Treverksmobler.vue'),
		meta: {
			title: 'Treverksmobler',
		},
		props: {
			topImage: require('@/assets/top-images/treverk_merged.jpg'),
		},
	},
	
	{
		path: '*',
		name: '404',
		component: () => import('../views/404.vue'),
	},
]
const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition) return savedPosition
	else return { x: 0, y: 0 }
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior,
})

export default router
