<template>
	<div>
		<NavHeader
			v-show="showHeader"
			:links="links"
			:isPhone="isPhone"
			@openNavDrawer="showDrawer = true"
			@closeNavDrawer="showDrawer = false"
		/>
		<NavDrawer v-show="showDrawer" :links="links" />
	</div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue'
import NavDrawer from '@/components/NavDrawer.vue'
export default {
	components: {
		NavHeader,
		NavDrawer,
	},
	data() {
		return {
			isPhone: false,
			showHeader: true,
			showDrawer: false,
			lastScrollPosition: 0,
			isTop: true,
			xDown: Number,
			YDown: Number,
			links: [
				{
					name: "Hjem",
					path: "/"
				},
				{
					name: 'Prosjekter',
					sublinks: [
						{
							name: 'Nybygg/Tilbygg',
							path: '/nybygg',
						},
						{
							name: 'Bad',
							path: '/bad',
						},
						{
							name: 'Kjøkken',
							path: '/kjokken',
						},
						{
							name: 'Vaskerom',
							path: '/vaskerom',
						},
						{
							name: 'Totalrenovering',
							path: '/totalrenovering',
						},
						{
							name: 'Spesialrom',
							path: '/spesialrom'
						},
						{
							name: 'Restaurering',
							path: '/restaurering'
						},
						{
							name: "Treverksmøbler",
							path: 'treverksmobler'
						}
					],
				},
				{
					name: 'Om oss',
					path: '/om-oss',
				},
				{
					name: 'Kontakt',
					path: '/kontakt',
				},
			],
		}
	},
	methods: {
		toggleNavDrawer() {
			if (this.isPhone) {
				this.showDrawer = !this.showDrawer
			}
		},
		onResize() {
			this.isPhone = window.innerWidth < 800 ? true : false
		},
		onScroll() {
			this.showDrawer = false
			const currentScrollPosition =
				window.pageYOffset || document.documentElement.scrollTop

			this.isTop = currentScrollPosition <= 100

			this.lastScrollPosition = currentScrollPosition
		},

		/* everything below is required for detecting swipes */
		getTouches(evt) {
			return (
				evt.touches || evt.originalEvent.touches // browser API
			) // jQuery
		},
		handleTouchStart(evt) {
			const firstTouch = this.getTouches(evt)[0]
			this.xDown = firstTouch.clientX
			this.yDown = firstTouch.clientY
		},
		handleTouchMove(evt) {
			if (!this.xDown || !this.yDown) {
				return
			}

			var xUp = evt.touches[0].clientX
			var yUp = evt.touches[0].clientY

			var xDiff = this.xDown - xUp
			var yDiff = this.yDown - yUp

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				/*most significant*/
				if (xDiff > 0) {
					/* left swipe */
					if (this.xDown > screen.width / 2 && this.isPhone)
						this.showDrawer = true
				} else {
					/* right swipe */
					this.showDrawer = false
				}
			} else {
				if (yDiff > 0) {
					/* up swipe */
					this.showDrawer = false
				} else {
					/* down swipe */
					this.showDrawer = false
				}
			}
			/* reset values */
			this.xDown = null
			this.yDown = null
		},
	},
	watch: {
		isPhone() {
			if (!this.isPhone) {
				this.showDrawer = false
				this.showHeader = true
			}
		},
		$route() {
			this.showDrawer = false
		},
	},
	created() {
		window.addEventListener('resize', this.onResize)
		window.addEventListener('scroll', this.onScroll)
		window.addEventListener('touchstart', this.handleTouchStart, false)
		window.addEventListener('touchmove', this.handleTouchMove, false)
		this.onResize()
	},
	destroyed() {
		window.removeEventListener('resize', this.onResize)
		window.removeEventListener('scroll', this.onScroll)
		window.removeEventListener('touchstart', this.handleTouchStart, false)
		window.removeEventListener('touchmove', this.handleTouchMove, false)
	},
}
</script>

<style scoped></style>
