<template>
	<div class="container">
		<div class="nav-header">
			<router-link class="logo-container" to="/" v-show="!isPhone">
				<img class="logo" src="@/assets/logo.png" alt="logo" />
			</router-link>
			<router-link class="logo-container-phone" to="/" v-show="isPhone">
				<img class="logo" src="@/assets/logo.png" alt="logo" />
			</router-link>
			<div class="links" v-show="!isPhone">
				<div class="link" v-for="(link, i) in links" :key="i">
					<router-link :to="link.path" v-if="link.path">
						<h4 class="link-name">{{ link.name }}</h4>
					</router-link>
					<div class="dropdown" v-else>
						<h4 class="link-name dropbtn">{{ link.name }}</h4>
						<div class="dropdown-content">
							<router-link
								v-for="(sublink, i) in link.sublinks"
								:key="i"
								:to="sublink.path"
							>
								<h6 class="link-name">{{ sublink.name }}</h6>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="drawer-icon-wrapper" v-show="isPhone">
				<v-icon
					@click="toggleDrawer()"
					v-show="isPhone"
					class="nav-drawer-icon"
					name="bars"
					scale="2"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		links: {
			type: Array,
			required: true,
			default: () => [],
		},
		isPhone: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			drawerOpen: false,
		}
	},
	methods: {
		toggleDrawer() {
			if (!this.drawerOpen) {
				this.drawerOpen = true
				this.$emit('openNavDrawer')
			} else {
				this.drawerOpen = false
				this.$emit('closeNavDrawer')
			}
		},
	},
}
</script>

<style scoped>
.container {
	background: #000;
	color: var(--color-3-on);
	width: 100%;
	padding: 0.75% 5%;
	filter: drop-shadow(1px 2px 4px #000);
}
.nav-header {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	align-items: center;
}
.links {
	white-space: nowrap;
	padding: var(--padding-1);
	display: flex;
	justify-content: space-between;
	padding: 0 15% 0 10%;
	font: roboto;
	font-weight: 100;
	font-size: 200%;
}

.link {
	margin: 10px;
}
.link a {
	color: inherit;
	text-decoration: none;
}
.logo-container {
	height: 60px;
	padding-left: 10%;
}

.logo {
	height: 100%;
}

.logo-container-phone img {
	display: inline-block;
	max-height: 50px;
}

.link-name {
	font-weight: 100;
}
.user {
	display: flex;
	align-items: center;
	margin: var(--padding-1);
}

.drawer-icon-wrapper {
	text-align: right;
}

.nav-drawer-icon {
	display: inline-block;
	margin-right: 4%;
}

.dropbtn {
	color: white;
	border: none;
}

.dropdown {
	display: inline-block;
}

.dropdown-content {
	position: absolute;
	margin-left: -1%;
	display: none;
	background-color: rgba(0, 0, 0, 0.47);
	min-width: 10%;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	text-align: center;
}

.dropdown-content a {
	color: white;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	background-color: #ddd;
}

.dropdown:hover .dropdown-content {
	display: block;
}

@media only screen and (max-width: 1400px) {
	.links {
		font-size: 150%;
	}
}
</style>
