<template>
	<div class="footer">
		<div class="container">
			<div class="content-grid">
				<div class="footer-item partner-row">
					<h3 class="title">Våre partnere</h3>
					<div class="partner-wrapper">
						<a href="https://www.malermesterberntsen.no/"
							><img
								class="partner"
								src="../assets/partners/mb.jpg"
						/></a>
						<a href="https://www.mjelektro.no/"
							><img
								class="partner"
								src="../assets/partners/mj.png"
						/></a>
						<a href="https://nesstra.no/"
							><img
								class="partner"
								src="../assets/partners/nesstra.jpg"
						/></a>
						<a href="https://www.monter.no/"
							><img
								class="partner"
								src="../assets/partners/optimera_monter.jpg"
						/></a>
						<a href="https://www.wiig.no/"
							><img
								class="partner"
								src="../assets/partners/ørnulf.jpg"
						/></a>
					</div>
				</div>
				<div class="text footer-item footer-container">
					<div class="footer-main-item">
						<h3 class="footer-title">Ellingsen Byggservice</h3>
						<div class="contact-info-wrapper">
							<div class="contact-info">
								Tlf:
								<a href="tlf:+4790663880"> +47 90 66 28 80</a>
							</div>
							<div class="contact-info">
								Email:
								<a href="mailto:post@ellingsenbyggservice.no"
									>post@ellingsenbyggservice.no</a
								>
							</div>
						</div>

						<div class="social-links">
							<a
								href="https://www.facebook.com/ellinsenbyggservice/"
								class="fa fa-facebook"
							>
								<img
									class="sm-icon"
									src="@/assets/icons/facebook.png"
								/>
							</a>
							<a
								href="https://www.instagram.com/ellingsen_byggservice_as/"
								class="fa fa-instagram"
							>
								<img
									class="sm-icon"
									src="@/assets/icons/instagram.png"
								/>
							</a>
						</div>

						<div class="footer-links">
							<div>
								<router-link to="/om-oss/">
									<h4 class="link-name">Om oss</h4>
								</router-link>
							</div>
							<div>
								<router-link to="/kontakt/">
									<h4 class="link-name">Kontakt</h4>
								</router-link>
							</div>
						</div>
					</div>
					<div class="footer-main-item">
						<div class="text footer-item">
							<h3 class="footer-title-centered">
								Du finner oss her
							</h3>
							<div class="mapouter">
								<div class="h_iframe">
									<iframe
										style="-webkit-filter: grayscale(100%) invert(92%) contrast(83%); filter: grayscale(100%) invert(92%) contrast(83%);"
										height="1"
										width="1"
										frameborder="0"
										id="gmap_canvas"
										src="https://maps.google.com/maps?q=Granveien%204,%201470&t=&z=13&ie=UTF8&iwloc=&output=embed"
									></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['links'],
}
</script>
<style scoped>
.footer-title-centered,
.footer-title,
.title {
	font-size: 1.5vw;
	font-weight: 400;
}

.footer-title,
.footer-title-centered {
	padding-bottom: 4%;
}

.title {
	padding-top: 3%;
	padding-bottom: 1%;
}

.footer-title-centered {
	text-align: center;
}
.container {
	background: rgba(0, 0, 0, 0.85);
	color: var(--color-3-on);
	padding: 0 10%;
}

.h4 {
	display: inline;
}

.content-grid {
	grid-template-columns: 1fr;
	grid-template-rows: 0.5fr 2fr 0.5fr;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.'
		'.';
	justify-content: center;
}

.footer-links {
	padding-top: 2%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-right: 50%;
	font-size: 1.5vw;
}

h4 {
	color: white;
}

.h_iframe {
	position: relative;
	padding-top: 56%;
}

.h_iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 60%;
	margin: 0 25%;
}

.footer-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0px 0px;
}

.footer-main-item {
	margin-top: 10%;
	/* Overwrites auto-sizing of flexbox from content size to 0 */
	min-width: 0;
	min-height: 0;
	overflow: hidden;
}

.contact-info {
	font-size: 1.4vw;
	line-height: 150%;
	padding-bottom: 2%;
}

.contact-info-wrapper {
	padding-bottom: 3%;
}

.contact-info a {
	text-decoration: none;
	color: white;
}

ul {
	list-style: none;
}

.social-links {
	display: flex;
	padding-bottom: 4%;
}

.content-grid {
	margin: 0;
}

.partner {
	display: inline-block;
	max-width: 8%;
	object-fit: cover;
	margin: 0 5%;
	vertical-align: middle;
	max-height: 50px;
}

.partner-wrapper {
	margin: 0 auto;
	display: inline;
}

.partner-row {
	text-align: center;
	padding: 2% 0 0 0;
	margin-left: auto;
	margin-right: auto;
}

.sm-icon {
	max-width: 4vw;
	padding-right: 40px;
}

@media only screen and (max-width: 1400px) {
	.sm-icon {
		max-width: 5vw;
	}
}

@media only screen and (max-width: 1200px) {
	.footer-title-centered,
	.footer-title,
	.footer-links,
	.contact-info,
	.title {
		font-size: 2vw;
	}

	.sm-icon {
		max-width: 6vw;
		padding-right: 40px;
	}

	.footer-container {
		padding-bottom: 10%;
	}
}

@media only screen and (max-width: 1000px) {
	.sm-icon {
		max-width: 7vw;
	}
}

@media only screen and (max-width: 800px) {
	.footer-title-centered,
	.footer-title,
	.footer-links,
	.contact-info,
	.title {
		font-size: 2.4vw;
	}

	.sm-icon {
		max-width: 9vw;
		padding-right: 40px;
	}

	.footer-container {
		padding-top: 5%;
	}

	.partner-row {
		padding-top: 4%;
	}

	.container {
		padding: 0 2.5%;
	}

	.partner {
		max-width: 12%;
		margin: 0 2.5%;
	}
}

@media only screen and (max-width: 600px) {
	.sm-icon {
		max-width: 11vw;
	}
}

@media only screen and (max-width: 400px) {
	.sm-icon {
		max-width: 15vw;
	}
}

@media only screen and (max-width: 300px) {
	.sm-icon {
		max-width: 17vw;
	}
}
</style>
