<template>
	<div class="nav-drawer">
		<div class="items links-container">
			<div class="links" v-for="(link, i) in links" :key="i">
				<router-link :to="link.path" class="link" v-if="link.path">
					<h3 class="link-name">{{ link.name }}</h3>
				</router-link>
				<div class="dropdown" v-else>
					<h3 class="link-name dropbtn" @click="toggleShowProject()">
						{{ link.name }}
					</h3>
					<div
						class="dropdown-content"
						:style="{ display: show_projects }"
					>
						<router-link
							v-for="(sublink, i) in link.sublinks"
							:key="i"
							:to="sublink.path"
						>
							<h6 class="link-name">{{ sublink.name }}</h6>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show_projects: 'none',
		}
	},
	props: {
		links: {
			type: Array,
			required: true,
		},
	},

	methods: {
		toggleShowProject() {
			if (this.show_projects == 'none') {
				this.show_projects = 'block'
			} else {
				this.show_projects = 'none'
			}
		},
	},
}
</script>

<style scoped>
.nav-drawer {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: auto;
	background-color: rgba(0, 0, 0, 0.92);
	color: var(--color-3-on);
	box-shadow: var(--box-shadow-3);
	padding: 3% 0;
}

/** 
.items {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: flex-start;
	flex-direction: column;
}
*/
.links-container {
	height: 100%;
	padding-bottom: 5%;
}

.link-name {
	font-weight: normal;
}

.items > * {
	margin: var(--padding-2);
}
.link {
	display: block;
	position: relative;
	color: var(--color-3-on);
	text-align: center;
	width: fit-content;
	margin: var(--padding-1) auto;
}
.link::after {
	position: absolute;
	content: '';
	background: var(--color-3-on);
	border-radius: 40px;
	width: 0%;
	height: 2px;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	transition: width var(--transition-speed-fast) ease-in-out;
}
.router-link-exact-active::after {
	width: 100%;
}

.nav-drawer-icon {
	font-size: 200px;
	cursor: pointer;
	user-select: none;
	color: var(--color-3-on);
	transition: color var(--transition-speed-fast) ease-in-out;
}
.exit {
	align-self: flex-end;
	margin: var(--padding-2) calc(var(--padding-content));
}

.dropdown {
	text-align: center;
}

.dropdown-content {
	display: none;
	width: 100%;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	text-align: center;
	border-bottom: 1px solid rgba(256, 256, 256, 0.5);
}

.dropdown-content a {
	color: white;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}
</style>
