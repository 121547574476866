<template>
	<div id="app">
		<div id="screen">
			<Nav />
			<router-view class="content"></router-view>
		</div>
		<Footer />
	</div>
</template>

<script>
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'

export default {
	components: {
		Nav,
		Footer,
	},
}
</script>

<style>
@import url('./assets/variables.css');
@import url('./assets/styles.css');
</style>
