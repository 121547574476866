<template>
	<div class="container">
		<picture>
			<source media="(max-width:500px)" :srcset="topImagePhone" />
			<img :src="topImage" />
		</picture>
		<!-- <img :src="topImage" :srcset="`${topImagePhone} 500w`" /> -->
	</div>
</template>

<script>
export default {
	props: ['topImage', 'topImagePhone'],
}
</script>

<style scoped>
.container img {
	padding: 0;
	display: block;
	margin: 0 auto;
	max-height: 900px;
	width: 80%;
}

@media only screen and (max-width: 1300px) {
	.container img {
		width: 90%;
	}
} 


.container {
	background-color: rgb(0, 0, 0, 0.95);
}
</style>
