import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ToggleButton from 'vue-js-toggle-button'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
Vue.component('v-icon', Icon)
Vue.use(ToggleButton)

Vue.config.productionTip = false

let vueHasBeenInitialized = false

if (!vueHasBeenInitialized) {
	new Vue({
		router,
		render: h => h(App),
	}).$mount('#app')
	vueHasBeenInitialized = true
}
